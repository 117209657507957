// GLOWEB-5448 RUBY users limitation
(function() {
	'use strict';

	var
		rubyMsgBar = document.querySelector('#ru-message-bar'),
		msgBar = document.querySelector('[data-cmp-name="message-bar"]:not(#ru-message-bar)');

	if (rubyMsgBar) {
		var xhr = new XMLHttpRequest();

		xhr.open('GET', avastGlobals.web.locale === 'ru-ru' ? 'https://' + avastGlobals.web.domain + '/geo-data' : 'https://' + avastGlobals.web.domain + '/' + avastGlobals.web.locale + '/geo-data');
		xhr.responseType = '';
		xhr.setRequestHeader('x-requested-with', 'XMLHttpRequest');
		xhr.send();

		xhr.onload = function () {
			var response = JSON.parse(xhr.response.split('<')[0]);

			if (response.region === 'RU' || response.region === 'BY') {
				if(msgBar) msgBar.style.display = 'none';
				rubyMsgBar.style.display = 'block';
			};
		};
	}
})()
